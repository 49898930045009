<template>
  <v-container>
    <div
      class="d-flex justify-space-between align-center mt-4 mb-4"
    >
      <h1
        v-translate
        translate-context="Page title"
        class="title"
      >
        My notifications
      </h1>

      <v-alert
        v-if="supportsWebPush && activeSubscription"
        type="success"
        dense
        text
        class="caption mb-0 ml-4"
        max-width="400px"
      >
        <template #prepend>
          <v-icon
            v-show="$vuetify.breakpoint.mdAndUp"
            class="success--text"
            left
          >
            check_circle
          </v-icon>
        </template>

        <div
          class="d-flex"
          :class="{
            'flex-column align-end': $vuetify.breakpoint.smAndDown,
          }"
        >
          <div
            v-translate
            class="py-1 px-2"
            :class="{
              'text-right': $vuetify.breakpoint.smAndDown,
            }"
          >
            Notifications are enabled on this device.
          </div>

          <div
            class="pa-1"
          >
            <v-menu
              offset-y
              bottom
              left
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  outlined
                  dark
                  color="secondary"
                  height="auto"
                  class="py-2"
                  v-on="on"
                >
                  <div
                    class="text-left"
                  >
                    <span
                      v-translate="{ br: '<br>' }"
                      render-html="true"
                    >
                      Manage
                      %{ br }
                      notifications
                    </span>
                  </div>

                  <v-icon
                    right
                  >
                    keyboard_arrow_down
                  </v-icon>
                </v-btn>
              </template>

              <m-web-push-subscription />
            </v-menu>
          </div>
        </div>
      </v-alert>
    </div>

    <v-alert
      v-if="supportsWebPush && !activeSubscription"
      text
      color="info"
      class="mb-6 caption"
      max-width="768px"
    >
      <div
        class="d-flex"
      >
        <h3 class="title mb-2">
          Would you like to get notifications on your device?
        </h3>

        <v-tooltip
          bottom
          content-class="pa-0"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-icon
              color="info"
              right
              class="mt-n2"
              v-on="tooltip"
            >
              info
            </v-icon>
          </template>

          <v-card
            max-width="400px"
            dark
          >
            <v-card-text>
              <span v-translate>
                Push notifications are realtime notifications that the application can
                send to your device.
              </span>
            </v-card-text>
          </v-card>
        </v-tooltip>
      </div>

      <span v-translate>
        Push notifications help you keep informed with critical events
        such as work interruptions or other urgent tasks.
      </span>

      <div
        class="mt-4"
      >
        <v-menu
          offset-y
          bottom
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="info"
              outlined
              small
              v-on="on"
            >
              <span v-translate>
                Subscribe to notifications
              </span>

              <v-icon
                right
              >
                keyboard_arrow_down
              </v-icon>
            </v-btn>
          </template>

          <m-web-push-subscription />
        </v-menu>
      </div>
    </v-alert>

    <v-expansion-panels
      v-if="notificationLogs.length > 0"
      hover
      multiple
    >
      <v-expansion-panel
        v-for="(log, i) in notificationLogs"
        :key="i"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);"
        @change="() => onLogClick(log)"
      >
        <v-expansion-panel-header
          :color="log.acknowledged ? null : 'info white--text'"
        >
          <div>
            <div>
              {{ log.title }}
            </div>

            <div
              class="caption mb-n1"
              :class="{
                'backgroundAccent--text text--darken-2': log.acknowledged,
              }"
            >
              {{ log.subtitle }}
            </div>
          </div>

          <template v-slot:actions>
            <div
              class="d-flex ml-4"
            >
              <div
                class="caption-xs mr-4 text-right"
                :class="{
                  'text--secondary': log.acknowledged,
                }"
              >
                <div
                  class="mb-1"
                >
                  {{ $dayjs(log.createdAt).fromNow() }}
                </div>

                <div>
                  {{ $dayjs(log.createdAt).format('DD.MM.YYYY HH:mm') }}
                </div>
              </div>

              <v-icon
                :color="log.acknowledged ? 'primary' : 'white'"
              >
                $expand
              </v-icon>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          class="pb-0"
        >
          <v-card-text>
            <div
              v-translate
              class="title"
            >
              More information
            </div>

            <div
              class="text--secondary body-2"
            >
              {{ log.body }}
            </div>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div
      v-else
      v-translate
    >
      No notifications
    </div>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import MWebPushSubscription from '@/components/MWebPushSubscription';

  export default {
    components: {
      MWebPushSubscription,
    },

    computed: {
      ...mapGetters({
        supportsWebPush: 'sw/supportsWebPush',
        activeSubscription: 'sw/activeSubscription',
        notificationLogs: 'notificationLogs/logs',
      }),
    },

    methods: {
      ...mapWaitingActions('notificationLogs', {
        acknowledgeLog: 'updating log',
      }),

      onLogClick(log) {
        if (log.acknowledged) return;
        this.acknowledgeLog({ id: log.id });
      },
    },
  };
</script>
