var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex justify-space-between align-center mt-4 mb-4"},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"title",attrs:{"translate-context":"Page title"}},[_vm._v(" My notifications ")]),(_vm.supportsWebPush && _vm.activeSubscription)?_c('v-alert',{staticClass:"caption mb-0 ml-4",attrs:{"type":"success","dense":"","text":"","max-width":"400px"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"success--text",attrs:{"left":""}},[_vm._v(" check_circle ")])]},proxy:true}],null,false,3571447129)},[_c('div',{staticClass:"d-flex",class:{
          'flex-column align-end': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"py-1 px-2",class:{
            'text-right': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v(" Notifications are enabled on this device. ")]),_c('div',{staticClass:"pa-1"},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"py-2",attrs:{"x-small":"","outlined":"","dark":"","color":"secondary","height":"auto"}},on),[_c('div',{staticClass:"text-left"},[_c('span',{directives:[{name:"translate",rawName:"v-translate",value:({ br: '<br>' }),expression:"{ br: '<br>' }"}],attrs:{"render-html":"true"}},[_vm._v(" Manage %{ br } notifications ")])]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" keyboard_arrow_down ")])],1)]}}],null,false,2285291711)},[_c('m-web-push-subscription')],1)],1)])]):_vm._e()],1),(_vm.supportsWebPush && !_vm.activeSubscription)?_c('v-alert',{staticClass:"mb-6 caption",attrs:{"text":"","color":"info","max-width":"768px"}},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"title mb-2"},[_vm._v(" Would you like to get notifications on your device? ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"pa-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mt-n2",attrs:{"color":"info","right":""}},tooltip),[_vm._v(" info ")])]}}],null,false,179868112)},[_c('v-card',{attrs:{"max-width":"400px","dark":""}},[_c('v-card-text',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Push notifications are realtime notifications that the application can send to your device. ")])])],1)],1)],1),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Push notifications help you keep informed with critical events such as work interruptions or other urgent tasks. ")]),_c('div',{staticClass:"mt-4"},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"color":"info","outlined":"","small":""}},on),[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" Subscribe to notifications ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" keyboard_arrow_down ")])],1)]}}],null,false,558388314)},[_c('m-web-push-subscription')],1)],1)]):_vm._e(),(_vm.notificationLogs.length > 0)?_c('v-expansion-panels',{attrs:{"hover":"","multiple":""}},_vm._l((_vm.notificationLogs),function(log,i){return _c('v-expansion-panel',{key:i,staticStyle:{"border-bottom":"1px solid rgba(0, 0, 0, 0.1)"},on:{"change":function () { return _vm.onLogClick(log); }}},[_c('v-expansion-panel-header',{attrs:{"color":log.acknowledged ? null : 'info white--text'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex ml-4"},[_c('div',{staticClass:"caption-xs mr-4 text-right",class:{
                'text--secondary': log.acknowledged,
              }},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$dayjs(log.createdAt).fromNow())+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$dayjs(log.createdAt).format('DD.MM.YYYY HH:mm'))+" ")])]),_c('v-icon',{attrs:{"color":log.acknowledged ? 'primary' : 'white'}},[_vm._v(" $expand ")])],1)]},proxy:true}],null,true)},[_c('div',[_c('div',[_vm._v(" "+_vm._s(log.title)+" ")]),_c('div',{staticClass:"caption mb-n1",class:{
              'backgroundAccent--text text--darken-2': log.acknowledged,
            }},[_vm._v(" "+_vm._s(log.subtitle)+" ")])])]),_c('v-expansion-panel-content',{staticClass:"pb-0"},[_c('v-card-text',[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"title"},[_vm._v(" More information ")]),_c('div',{staticClass:"text--secondary body-2"},[_vm._v(" "+_vm._s(log.body)+" ")])])],1)],1)}),1):_c('div',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v(" No notifications ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }